import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 定期检查列表
export function getCheckItem(data: any) {
  return request({
    url: "/factory/checkItem/listAll",
    method: "get",
    params: data,
  });
}

// 统计数量
export function getReportCount(data: any) {
  return request({
    url: "/factory/checkItem/reportCount",
    method: "get",
    params: data,
  });
}

// 新增点检
export function saveCheckItem(data: any) {
  return request({
    url: "/factory/checkItemLog/save",
    method: "post",
    data: data,
  });
}

// 查看检查记录
export function getItemLog(data: any) {
  return request({
    url: "/factory/checkItemLog/getCheckItemLog",
    method: "get",
    params: data,
  });
}
